import React, { memo } from 'react';
import Head from 'next/head';

type TFAQPageElement = {
  question: string;
  description: string;
};

interface IOwnProps {
  faqPageElements: TFAQPageElement[];
}

interface IMainEntity {
  '@type': 'Question';
  name: string;
  acceptedAnswer: {
    '@type': 'Answer';
    text: string;
  };
}

const FAQPageJsonLd = ({ faqPageElements }: IOwnProps) => {
  const prepareJsonLdObject = () => {
    if (!faqPageElements?.length) return null;

    const preparedObject: {
      '@context': string;
      '@type': 'FAQPage';
      mainEntity: IMainEntity[];
    } = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [],
    };

    faqPageElements.forEach((faqPageElement) => {
      preparedObject.mainEntity.push({
        '@type': 'Question',
        name: `${faqPageElement.question}`,
        acceptedAnswer: {
          '@type': 'Answer',
          text: `${faqPageElement.description}`,
        },
      });
    });
    return preparedObject;
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(prepareJsonLdObject()),
        }}
      />
    </Head>
  );
};

export default memo(FAQPageJsonLd);
