import CaseFilter from '@/components/CaseFilter/CaseFilter';
import OverFooterBlock from '@/components/Layout/Footer/OverFooterBlock/OverFooterBlock';
import { PageHead } from '@/components/PageHead';
import { track } from '@amplitude/analytics-browser';
import { ApolloQueryResult } from '@apollo/client';
import { filterPriceAtom } from 'atoms/filter-price-atom';
import { menuAtom } from 'atoms/menu-height-atom';
import { weaponTypesAtom } from 'atoms/weapon-types-atom';
import { useAtomValue } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { IMeta } from 'types/common';
import client from '../../apollo-client';
import {
  FreeLootbox,
  GetMainpageFilterParamsDocument,
  GetMainpageFilterParamsQuery,
  GetPageMetasDocument,
  GetPageMetasQuery,
  GetPageMetasQueryVariables,
  MpLootboxesCategoryModel,
  MpLootboxesFilterModel,
} from '@/generated/projectlb-hasura';
import {
  GetLootboxesAndPromosDocument,
  GetLootboxesAndPromosQuery,
} from '@/generated/lootbox.generated';

export const FILTER_MAX_VALUE = 5000;

const DynamicCaseSection = dynamic(
  () => import('@/components/case/CaseSection/CaseSection'),
  {
    ssr: true,
  }
);

export interface Categories {
  name: string;
  anchor: string;
}

/**
 * Главная страница
 */

const Home = ({
  cases,
  metas,
  filterData,
}: {
  cases: MpLootboxesCategoryModel[] | null | undefined;
  freeCases: FreeLootbox[] | null | undefined;
  metas: IMeta | null | undefined;
  filterData: MpLootboxesFilterModel | null | undefined;
}) => {
  const { height: scrollOffset } = useAtomValue(menuAtom);

  const casesWithoutBonus =
    cases && cases.length > 1 ? [...cases.slice(1)] : null;

  //данные для фильтра
  const priceFilter = useAtomValue(filterPriceAtom);
  const weaponTypesFilter = useAtomValue(weaponTypesAtom);

  useHydrateAtoms([
    [filterPriceAtom, [filterData?.min_price, FILTER_MAX_VALUE]],
  ] as const);

  const filteredCases = casesWithoutBonus?.reduce(
    (result: MpLootboxesCategoryModel[], section) => {
      const filteredLootboxes = section.lootboxes.filter((lb) => {
        const priceIsOk =
          lb.price! >= priceFilter[0] &&
          (priceFilter[1] === FILTER_MAX_VALUE || lb.price! <= priceFilter[1]);
        const weaponTypesIsOk =
          JSON.stringify(weaponTypesFilter) === JSON.stringify(['all'])
            ? true
            : weaponTypesFilter.some((t) => lb.weapon_types.includes(t));
        return priceIsOk && weaponTypesIsOk;
      });

      if (filteredLootboxes.length !== 0) {
        result.push({ ...section, lootboxes: filteredLootboxes });
      }

      return result;
    },
    []
  );

  const categoriesNames = filteredCases?.map((category) => ({
    name: category.title.toUpperCase(),
    anchor: category.anchor,
  }));

  useEffect(() => {
    //временный фикс первого захода на главную сразу после авторизации
    const timeoutId = setTimeout(() => {
      const userFromStorage = localStorage.getItem('hd_token');
      const isAuth = JSON.parse(userFromStorage!)?.isAuthorized;
      track('open page', {
        subevent: 'index',
        auser: isAuth || false,
      });
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <PageHead title={metas?.title} description={metas?.description} />
      {categoriesNames && filterData && (
        <CaseFilter
          initialFilter={filterData}
          categoriesNames={categoriesNames}
        />
      )}
      <div id="cases" style={{ scrollMarginTop: scrollOffset - 56 }}>
        {filteredCases?.map((section, index) => (
          <DynamicCaseSection
            id={section.anchor}
            key={index}
            tooltipText={section.info_text}
            title={section.title}
            cases={section.lootboxes}
          />
        ))}
      </div>
      <div style={{ marginTop: 'auto' }}>
        <OverFooterBlock />
      </div>
    </>
  );
};

export const getStaticProps = async () => {
  const [main, filter, meta] = await Promise.allSettled([
    client.query({
      query: GetLootboxesAndPromosDocument,
    }),
    client.query({
      query: GetMainpageFilterParamsDocument,
    }),
    client.query({
      query: GetPageMetasDocument,
      variables: {
        metas_input: { global_key: 'default', sub_key: 'default' },
      } as GetPageMetasQueryVariables,
    }),
  ]);

  const initialFilterData: ApolloQueryResult<GetMainpageFilterParamsQuery> | null =
    filter.status === 'fulfilled' ? filter.value : null;

  const mainResult: ApolloQueryResult<GetLootboxesAndPromosQuery> | null =
    main.status === 'fulfilled' ? main.value : null;

  const metaResult: ApolloQueryResult<GetPageMetasQuery> | null =
    meta.status === 'fulfilled' ? meta.value : null;

  return {
    props: {
      cases: mainResult?.data.GetMainpageLootboxes.lootbox_categories,
      metas: metaResult?.data.GetPageMetas?.metas
        ? metaResult?.data.GetPageMetas?.metas
        : null,
      filterData: initialFilterData?.data.GetMainpageLootboxFilterParams,
    },
    revalidate: 600, // 10 minutes
  };
};

export default Home;
