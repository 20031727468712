import {
  FormControl,
  InputLabel,
  MenuItem,
  MenuItemProps,
  Select,
  SelectProps,
  styled,
  SvgIcon,
  SvgIconProps,
} from '@mui/material';
import styles from './FilterSelect.module.scss';

const IconDropDown = (props: SvgIconProps) => {
  return (
    <SvgIcon
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
      sx={{ fill: 'none' }}
    >
      <path
        d="M6 9L9.73726 12.7373C10.5293 13.5293 10.9253 13.9253 11.382 14.0737C11.7837 14.2042 12.2163 14.2042 12.618 14.0737C13.0747 13.9253 13.4707 13.5293 14.2627 12.7373L18 9"
        stroke="#939BC2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

type IFilterSelect = SelectProps & {
  inputLabel?: string;
};

export const FilterSelect = styled(({ ...props }: IFilterSelect) => (
  <FormControl className={styles.form} variant="filled">
    {props.inputLabel && (
      <InputLabel
        variant="filled"
        sx={{
          color: '#939BC2',
          fontFamily: `'Play', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols'`,
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '1.71',
          letterSpacing: '0.28px',
          textTransform: 'uppercase',
          '&.Mui-focused': { color: '#939BC2' },
        }}
      >
        {props.inputLabel}
      </InputLabel>
    )}

    <Select
      IconComponent={IconDropDown}
      label={props.inputLabel}
      MenuProps={{
        sx: {
          '&& .Mui-selected': {
            backgroundColor: '#171e43',
          },
          '&& .MuiPaper-root': {
            background: '#252E5C',
            maxHeight: '200px',
            overflowY: 'auto',
          },
          '&& .MuiList-root': {
            background: '#252E5C',
          },
        },
        PopoverClasses: { paper: styles.select_scroll },
      }}
      sx={{
        borderRadius: '12px',
        background: '#252E5C',
        border: '1px solid rgba(147, 155, 194, 0.24)',

        '&:hover': {
          border: '1px solid rgba(147, 155, 194, 0.64)',
          transition: 'border 0.5s',
          background: '#252E5C',
        },

        '&.Mui-focused': {
          backgroundColor: '#252E5C',
        },

        '&::after': {
          background: '#252E5C',
          content: 'none',
        },

        '&::before': {
          background: '#252E5C',
          content: 'none',
        },
      }}
      {...props}
    />
  </FormControl>
))(() => ({
  '& .MuiInputBase-input': {
    color: '#FFF',
    fontFamily: `'Play', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols'`,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '1.71',
    letterSpacing: '0.28px',
    textTransform: 'uppercase',
  },
}));

export const FilterMenuItem = styled(({ ...props }: MenuItemProps) => (
  <MenuItem
    sx={{
      color: '#FFF',
      fontSize: '12px',
      fontFamily: `'Play', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols'`,
      fontWeight: '600',
      letterSpacing: '0.04em',
      textTransform: 'uppercase',
    }}
    {...props}
  />
))(() => ({}));
