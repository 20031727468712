import React from 'react';
import styles from './CaseFilter.module.scss';
import Filters from './Filters/Filters';
import { MpLootboxesFilterModel } from '@/generated/projectlb-hasura';

interface CaseFilterProps {
  initialFilter: MpLootboxesFilterModel;
  categoriesNames: { name: string; anchor: string }[];
}

const CaseFilter = (props: CaseFilterProps) => {
  const { initialFilter, categoriesNames } = props;

  return (
    <div className={styles.container}>
      <Filters
        initialFilter={initialFilter}
        categoriesNames={categoriesNames}
      />
    </div>
  );
};

export default CaseFilter;
