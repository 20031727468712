export const textOverFooter = [
  {
    id: 1,
    text: 'Как открывать кейсы КСГО на HyperDrop',
  },
  {
    id: 2,
    text: 'Покупка кейсов',
  },
  {
    id: 3,
    text: 'Регистрация и личный кабинет HyperDrop',
  },
  {
    id: 4,
    text: 'Пополнение счета и вывод скинов на HyperDrop',
  },
  {
    id: 5,
    text: 'Бонусы и акции HyperDrop',
  },
  {
    id: 6,
    text: 'Мобильная версия',
  },
  {
    id: 7,
    text: 'Преимущества игры на HyperDrop',
  },
  {
    id: 8,
    text: 'FAQ – вопросы и ответы',
  },
];

export const faqElements = [
  {
    question: 'Можно ли пополнить счет с баланса Steam?',
    description: 'Нет, данная опция ограничена правилами Valve.',
  },
  {
    question: 'Что будет, если не вывести полученные скины в течение месяца?',
    description:
      'Система автоматически продаст их по текущей рыночной стоимости, зачислив деньги на баланс пользователя.',
  },
  {
    question: 'Сколько ждать зачисления денег на счет?',
    description:
      'Скорость обработки операции зависит от конкретного метода совершения платежа. Если средства не поступили в течение часа – рекомендуется обратиться в службу поддержки сайта.',
  },
];
