import { faqElements, textOverFooter } from '@/configs/textOverFooter';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { menuAtom } from 'atoms/menu-height-atom';
import { createRef, useState } from 'react';
import FaqpageJsonLd from 'seo/faqpage-json-ld';
import styles from './OverFooterBlock.module.scss';
import { useAtomValue } from 'jotai';

const OverFooterBlock = () => {
  const [open, setOpen] = useState(false);

  const [refs, setRefs] = useState(
    new Array(8).fill(null).map(() => createRef<HTMLDivElement>())
  );
  const { height } = useAtomValue(menuAtom);

  const handleClick = () => {
    setOpen((old) => !old);
  };

  const executeScroll = async (index: number) => {
    await setOpen(true);
    refs[index]?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <>
      <FaqpageJsonLd faqPageElements={faqElements} />
      <div className={styles.main_container}>
        <Typography className={styles.title}>HyperDrop</Typography>
        <div className={styles.content_container}>
          {!open && <div className={styles.shadow}></div>}

          <Box sx={{ flexGrow: 1 }} className={styles.mainContent}>
            <Grid container className={styles.grid_container}>
              {textOverFooter.map((obj, index) => {
                return (
                  <Grid key={index} className={styles.grid}>
                    <div
                      className={styles.mapBlock}
                      onClick={() => executeScroll(index)}
                    >
                      <div className={styles.numberBg}>0{obj.id}</div>
                      <Typography className={styles.content}>
                        {obj.text}
                      </Typography>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <>
            <div
              className={styles.openBlock}
              style={{
                maxHeight: open ? '100%' : '0px',
                marginTop: open ? '40px' : '0',
              }}
            >
              <div className={styles.line}></div>
              <Typography className={styles.text}>
                Все поклонники КС ГО хотят собрать престижный инвентарь, в
                котором будут не только скины на различные виды оружия, но и
                перчатки, модели агентов, легендарные ножи, некоторые из которых
                стоят тысячи долларов. Сайт HyperDrop обеспечивает такую
                возможность, предлагая зарегистрированным пользователям открытие
                кейсов, заметно отличающихся от привычных ящиков Valve. Не
                секрет, что из стандартных лутбоксов от Гейба чаще всего
                выпадает бесполезный ширп, который выглядит максимально скромно,
                никак не подчеркивает статус владельца, и приносит копейки при
                продаже на торговой площадке. Если сравнить стоимость отдельно
                взятой «Африканской сетки», пиксельного камуфляжа «Город» или
                «Пыльника» с суммой, которую приходится тратить, чтобы открыть
                кейсы – отсутствие выгоды очевидно. HyperDrop исправляет эту
                ситуацию, предлагая реальный вывод через Стим скинов, которые
                дают кейсы КС ГО без ключа.
              </Typography>
              <div
                style={{
                  scrollMarginTop: height,
                }}
                ref={refs[0]}
              >
                <Typography className={styles.titleText} component="h2">
                  Как открывать кейсы КС ГО на HyperDrop
                </Typography>
                <Typography className={styles.textList}>
                  Список доступных вариантов представлен на главной странице
                  сайта HyperDrop. Первоначально следует пройти авторизацию либо
                  сразу приступить к изучению содержимого. В число доступных
                  категорий входят:
                </Typography>
                <ol className={styles.ol}>
                  <li className={styles.textList}>
                    Лимитированная серия. Специальные кейсы с перчатками, ножами
                    и дорогостоящими скинами, количество открытий которых
                    регламентировано лимитом. Чем выгоднее предложение – тем
                    быстрее оно расходуется наиболее активными клиентами
                    сервиса. В данном случае стоит рассмотреть опцию
                    последовательного открытия нескольких ящиков подряд. Один из
                    наиболее интересных и популярных вариантов – кейс «M4A4», из
                    которого могут выпасть по-настоящему редкие предметы.
                  </li>
                  <li className={styles.textList}>
                    Наша сборка. Собственные коллекции, подготовленные
                    администрацией сервиса, и предлагающие возможность открывать
                    кейсы КС ГО 24 часа подряд – в пределах максимально
                    возможного ограничения на 5 000 экземпляров. В подборке
                    представлены как недорогие ящики, предназначенные для
                    быстрого фарма престижных скинов, так и премиальные
                    контейнеры на AWP, ножи и перчатки.
                  </li>
                  <li className={styles.textList}>
                    Стандартные. Наборы, дублирующие классические контейнеры,
                    выпущенные Valve за время поддержки игры – в честь памятных
                    операций, мейджоров, других событий. Это те же самые кейсы
                    КС ГО, но их не нужно открывать через Стим и запуск
                    приложения, тратя каждый раз около 200 рублей на виртуальные
                    ключи. Поклонникам редких скинов стоит обратить внимание на
                    лутбоксы «Гидра», «Браво» и «CS:GO Оружейный», предлагающие
                    возможность получения редких предметов.
                  </li>
                </ol>
                <Typography className={styles.text}>
                  В отдельную категорию – «Горячее предложение», вынесены ящики
                  из различных разделов, на которые действуют скидки от сайта
                  HyperDrop. Воспользовавшись выгодным предложением,
                  пользователи получают лучшие скины с минимумом вложений,
                  пополняя и прокачивая собственный инвентарь КС ГО.
                </Typography>
              </div>
              <div
                style={{
                  scrollMarginTop: height,
                }}
                ref={refs[1]}
              >
                <Typography className={styles.titleText} component="h3">
                  Покупка кейсов
                </Typography>
                <Typography className={styles.text}>
                  Алгоритм открытия кейсов на HyperDrop максимально прост. Чтобы
                  получить доступ к полному функционалу сайта, достаточно пройти
                  процедуру авторизации и пополнить баланс удобным способом.
                  После этого открыть главную страницу и выбрать один из
                  представленных вариантов, по умолчанию отсортированных по
                  возрастанию стоимости в каждой из категорий.
                  <br />
                  <br />
                  Практика показывает, что даже самый недорогой вариант может
                  окупиться более чем десятикратно. Лояльная политика сервиса
                  позволяет фармить подходящие скины до тех пор, пока не
                  дропнется нужный вариант. Любой предмет, выпавший из кейса с
                  ножами или перчатками CS: GO, можно сразу продать на торговой
                  площадке по актуальной рыночной стоимости, соответствующей
                  котировкам Steam. Деньги зачисляются на баланс моментально,
                  доступны для покупки новых лутбоксов.
                </Typography>
              </div>
              <div
                style={{
                  scrollMarginTop: height,
                }}
                ref={refs[2]}
              >
                <Typography className={styles.titleText} component="h2">
                  Регистрация и личный кабинет HyperDrop
                </Typography>
                <Typography className={styles.textList}>
                  Процедура создания учетной записи занимает минимум времени.
                  Достаточно выполнить простую последовательность действий:
                </Typography>
                <ol className={styles.ol}>
                  <li className={styles.textList}>
                    Открыть официальный сайт, расположенный по адресу
                    hyper-drop.com.
                  </li>
                  <li className={styles.textList}>
                    Выбрать в правой верхней части экрана кнопку с изображением
                    значка Steam.
                  </li>
                  <li className={styles.textList}>
                    На вновь открывшейся вкладке подтвердить согласие с
                    использованием данных аккаунта.
                  </li>
                  <li className={styles.textList}>
                    Автоматически вернуться на главную страницу сервиса.
                  </li>
                </ol>
                <Typography className={styles.text}>
                  Данные профиля Стима, предоставляемые сервису, не содержат
                  конфиденциальной или компрометирующей пользователя информации
                  — беспокоиться за безопасность нет оснований.
                  <br />
                  <br />
                  После регистрации открывается доступ к личному кабинету, в
                  котором основной интерес для начинающих представляет поле для
                  вставки трейд-ссылки. Достаточно кликнуть на пункте меню и
                  нажать на вкладку «Где получить», чтобы система переадресовала
                  на нужный раздел онлайн-магазина от Valve, после чего
                  скопировать уже выделенный вариант и ввести его в форму в ЛК
                  HyperDrop. Эта функция позволяет совершать сделки с ботом
                  продавца, то есть обеспечивает реальный вывод скинов,
                  полученных из кейсов CS GO, в инвентарь пользователя.
                  <br />
                  <br />В личном кабинете удастся посмотреть данные по
                  количеству полученных предметов, выполненных контрактов или
                  апгрейдов, ознакомиться с текущим содержимым инвентаря на
                  сервисе (лимит по хранению – не более 30 дней), изучить
                  собственную статистику по лучшим дропам и любимым кейсам.
                </Typography>
              </div>
              <div
                style={{
                  scrollMarginTop: height,
                }}
                ref={refs[3]}
              >
                <Typography className={styles.titleText} component="h2">
                  Пополнение счета и вывод скинов на HyperDrop
                </Typography>
                <Typography className={styles.textList}>
                  Перед тем, как приступить к открытию ящиков, нужно пополнить
                  баланс. Открыть соответствующее меню интерфейса можно как из
                  учетки, так и с главной страницы – кликнув на иконку с
                  изображением бумажника. На выбор пользователей доступных
                  следующие опции:
                </Typography>
                <ul className={styles.ol}>
                  <li className={styles.textList}>
                    банковские карты VISA, MasterCard и МИР;
                  </li>
                  <li className={styles.textList}>
                    переводы через Систему быстрых платежей;
                  </li>
                  <li className={styles.textList}>онлайн-банкинг;</li>
                  <li className={styles.textList}>
                    электронные кошельки Qiwi и Yoomoney;
                  </li>
                  <li className={styles.textList}>
                    криптовалютные токены Tether.
                  </li>
                </ul>
                <Typography className={styles.text}>
                  Минимальная сумма пополнения на HyperDrop зависти от
                  выбранного платежного сервиса, и варьируется в диапазоне от
                  100 до 1000 рублей. Для максимальной разовой транзакции
                  аналогичное значение составляет от 50 000 до 100 000.
                  <br />
                  <br />
                  Вывод полученных скинов осуществляется через трейд-ссылку, с
                  помощью автоматизированного бота. Достаточно подать
                  соответствующую заявку в личном кабинете, а затем принять
                  предложение в Steam. Перед подтверждением любой операции
                  рекомендуется убедиться в достоверности контрагента –
                  сервисные боты никогда не предлагают оформить платные
                  переводы, зачислить им скины в обмен на более выгодные
                  варианты, которые «будут отправлены позже».
                </Typography>
              </div>
              <div
                style={{
                  scrollMarginTop: height,
                }}
                ref={refs[4]}
              >
                <Typography className={styles.titleText} component="h2">
                  Бонусы и акции HyperDrop
                </Typography>
                <Typography className={styles.text}>
                  {/* На сегодняшний день сервис предлагает новичкам, готовым
                  открывать лучшие кейсы КС ГО,{' '}
                  <Link href={'/bonuses'} passHref>
                    <a style={{ textDecoration: 'none', color: '#d3f85a' }}>
                      бонус
                    </a>
                  </Link>{' '}
                  в размере до 50% при пополнении счета на сумму свыше 300
                  рублей. Активные пользователи найдут дополнительные промокоды,
                  размещаемые на тематических ресурсах и площадках.
                  <br />
                  <br /> */}
                  В ближайшей перспективе появятся новые маркетинговые опции:
                  программа лояльности, бесплатные кейсы каждые 24 часа, Колесо
                  Фортуны КС ГО, позволяющее испытать удачу и выиграть ценные
                  призы – начиная с пополнения баланса, и заканчивая
                  возможностью открыть дорогостоящий лутбокс без собственных
                  вложений.
                </Typography>
              </div>
              <div
                style={{
                  scrollMarginTop: height,
                }}
                ref={refs[5]}
              >
                <Typography className={styles.titleText} component="h2">
                  Мобильная версия
                </Typography>
                <Typography className={styles.text}>
                  Отсутствие профильных приложений HyperDrop для скачивания на
                  Android и iOS объясняется просто — сайт разработан с учетом
                  требований, предъявляемых современными мобильными браузерами,
                  отличается высокой производительностью и качественной
                  оптимизацией функционала. Открывать кейсы с ножами и
                  перчатками CS GO можно в любое время – достаточно
                  зарегистрироваться и внести минимальный депозит.
                </Typography>
              </div>
              <div
                style={{
                  scrollMarginTop: height,
                }}
                ref={refs[6]}
              >
                <Typography className={styles.titleText} component="h2">
                  Преимущества игры на HyperDrop
                </Typography>
                <Typography className={styles.textList}>
                  По мнению постоянных клиентов сервиса HyperDrop, ресурс
                  выгодно отличается от конкурентов такими особенностями:
                </Typography>
                <ol className={styles.ol}>
                  <li className={styles.textList}>
                    Широкий выбор уникальных кейсов для КС ГО по низким ценам.
                  </li>
                  <li className={styles.textList}>
                    Быстрый вывод скинов через трейд-бота и хранение в инвентаре
                    до 30 дней.
                  </li>
                  <li className={styles.textList}>
                    Возможность моментальной продажи по рыночной стоимости
                    Steam.
                  </li>
                  <li className={styles.textList}>
                    Наличие круглосуточной службы поддержки (онлайн-чат и
                    Телеграм).
                  </li>
                </ol>
                <Typography className={styles.text}>
                  Пользователи с определенным уровнем медиа популярности –
                  владельцы пабликов, блогов или тематических каналов – могут
                  отправить на рассмотрение заявку о сотрудничестве с HyperDrop.
                  При положительном рассмотрении предоставляются реферальные
                  ссылки, способные принести дополнительный доход с каждого
                  нового участника.
                </Typography>
              </div>
              <div
                style={{
                  scrollMarginTop: height,
                }}
                ref={refs[7]}
              >
                <Typography className={styles.titleText} component="h3">
                  FAQ – вопросы и ответы
                </Typography>
                <Typography className={styles.faqQuestions}>
                  Можно ли пополнить счет с баланса Steam?
                </Typography>
                <Typography className={styles.faqAnswer}>
                  Нет, данная опция ограничена правилами Valve.
                </Typography>
                <Typography className={styles.faqQuestions}>
                  Что будет, если не вывести полученные скины в течение месяца?
                </Typography>
                <Typography className={styles.faqAnswer}>
                  Система автоматически продаст их по текущей рыночной
                  стоимости, зачислив деньги на баланс пользователя.
                </Typography>
                <Typography className={styles.faqQuestions}>
                  Сколько ждать зачисления денег на счет?
                </Typography>
                <Typography className={styles.faqAnswer}>
                  Скорость обработки операции зависит от конкретного метода
                  совершения платежа. Если средства не поступили в течение часа
                  – рекомендуется обратиться в службу поддержки сайта.
                </Typography>
              </div>
            </div>
          </>
        </div>

        <Typography onClick={handleClick} className={styles.readMore}>
          {open ? 'ЗАКРЫТЬ' : 'ПОКАЗАТЬ ВСЕ'}
        </Typography>
      </div>
    </>
  );
};

export default OverFooterBlock;
